import { PlatformDetection } from "@ephox/sand";
import "@/styles/_splash_screen.scss";
import getEnv from "@/helpers/env";

unregisterSW();

const platform = PlatformDetection.detect();
console.log(platform);

const isSupportedBrowser = () => {
  const isOldIosSafari =
    platform.os.isiOS() &&
    (platform.os.version.major < 14 || (platform.os.version.major === 14 && platform.os.version.minor < 5));

  const isOldDesktopSafari =
    !platform.os.isiOS() &&
    platform.browser.isSafari() &&
    (platform.browser.version.major < 14 ||
      (platform.browser.version.major === 14 && platform.browser.version.minor < 1));

  const isOldChromium = platform.browser.isChromium() && platform.browser.version.major < 81;

  const isOldEdge = platform.browser.isEdge() && platform.browser.version.major < 81;

  const isOldOpera = platform.browser.isOpera() && platform.browser.version.major < 69;

  const isOldFirefox = platform.browser.isFirefox() && platform.browser.version.major < 86;

  const isIE = platform.browser.isIE();

  return !(
    platform.os &&
    (isOldIosSafari || isOldDesktopSafari || isOldChromium || isOldEdge || isOldOpera || isOldFirefox || isIE)
  );
};

const redirectToKeycloak = ({ lang, type, params }) => {
  window.location.replace(
    `${getEnv("VUE_APP_KEYCLOAK_URL")}/realms/${getEnv("VUE_APP_KEYCLOAK_REALM")}/protocol/openid-connect/${
      type || "auth"
    }?client_id=${getEnv("VUE_APP_KEYCLOAK_CLIENT_ID")}&redirect_uri=${getEnv(
      "VUE_APP_URL"
    )}&response_mode=fragment&response_type=code&scope=openid&ui_locales=${lang}${params}`
  );
};

const isLinkToKeycloak = (): boolean => {
  const url = new URL(window.location.href);
  const params = url.search ? url.search.replaceAll("?", "&") : "";
  const paths = url.pathname.split("/");
  if (paths[2] === "registration") {
    redirectToKeycloak({ lang: paths[1], type: "registrations", params: params });
    return true;
  } else if (paths[2] === "login") {
    redirectToKeycloak({ lang: paths[1], type: "auth", params: params });
    return true;
  }

  return false;
};

const isMaintenanceModeEnabled =
  getEnv("VUE_APP_MAINTENANCE_MODE") === true || getEnv("VUE_APP_MAINTENANCE_MODE") === "true";

if (isMaintenanceModeEnabled && !window.localStorage.getItem("disable_maintenance_mode")) {
  document.title = "Artbutler - Maintenance";
  document.body.innerHTML = `
  <div class="warning-screen">
  <div class="warning-screen__center">
    <img src="/img/icons/android-chrome-maskable-512x512.png" alt="ABX Logo">
    <h1 class="warning-screen__head">Maintenance in progress</h1>
    <p class="warning-screen__message">The application is currently undergoing maintenance and will be back soon.</p>
  </div>
</div>`;
} else {
  if (isSupportedBrowser()) {
    if (!isLinkToKeycloak()) {
      import("@/app");
    }
  } else {
    document.title = "Artbutler - Your browser is outdated";
    document.body.innerHTML = `
<div class="warning-screen">
  <div class="warning-screen__center">
    <img src="/img/icons/android-chrome-maskable-512x512.png" alt="ABX Logo">
    <h1 class="warning-screen__head">Your browser is outdated</h1>
    <p class="warning-screen__message">You are using the old version of your browser.<br>Please update your browser to the newest version</p>
  </div>
</div>`;
    console.warn("Browser is not supported", platform.browser);
  }
}

function unregisterSW() {
  navigator.serviceWorker?.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister().then(() => {
        window.location.reload();
      });
    }
  });
}
